/*-------------------------
  Helper Classes
-------------------------*/

.bg-color-dark {
    background-color: var(--color-dark);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}

// Color
.color-gray-4 {
    color: var(--color-gray-4);
}

// Opacity
.opacity-50 {
    opacity: 0.5;
}
.contentDescription{
    line-height: 200% !important;

}
.testimonialDescription{
    min-height: 270px;
    font-size: medium;
    line-height: normal;
}
.professional{
    background-color: #fff;
}